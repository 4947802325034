import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { XmlHttpRequestHelper } from '@shared/helpers/XmlHttpRequestHelper';
import { LocalStorageService } from '@shared/utils/local-storage.service';

@Injectable()
export class AppAuthService {

    logout(reload?: boolean, returnUrl?: string): void {

        // if user has used external login (such as BE-Login with or without ADFS)
        // we don't redirect to login-form after logout. instead we use the logout
        // redirect provided by appsettings:
        // for automatically logins (?autoLogin=BE-Login&idpHin=adfs)
        // for direct logins with login-form
        if (!returnUrl) {
            let localStorage = new LocalStorageService();
            const self = this;
            localStorage.getItem(AppConsts.authorization.autoLoginInfo, function (err, value) {
                let postLogoutRedirectUri;
                if (value?.idpHint) {
                    let postfix = value.isAutoLogin ? 'AutoLoginInfo' : 'NoAutoLoginInfo';
                    let autoLoginInfo = abp.setting.values['ExternalLoginProvider.BeLogin.' + postfix] ?? abp.setting.values['ExternalLoginProvider.EKonto.' + postfix];
                    if (autoLoginInfo && autoLoginInfo.length > 0) {
                        let idpLoginInfo = JSON.parse(autoLoginInfo).find(x => x.IdpHint == value.idpHint);
                        if (idpLoginInfo){
                            postLogoutRedirectUri = idpLoginInfo.Value;
                        }
                    }
                }

                if (postLogoutRedirectUri) {
                    self.logoutInternal(true, postLogoutRedirectUri);
                } else {
                    self.logoutInternal(reload, returnUrl);
                }
            });
        } else {
            this.logoutInternal(reload, returnUrl);
        }
    }

    private logoutInternal(reload?: boolean, returnUrl?: string): void {
        let customHeaders: { [key: string]: string | number } = {
            [abp.multiTenancy.tenantIdCookieName]: abp.multiTenancy.getTenantIdCookie(),
        };

        if (!abp.auth.isHttpOnlyCookieEnabled) {
            customHeaders['Authorization'] = 'Bearer ' + abp.auth.getToken();
        }

        XmlHttpRequestHelper.ajax(
            'GET',
            AppConsts.remoteServiceBaseUrl + '/api/TokenAuth/LogOut',
            customHeaders,
            null,
            () => {
                abp.auth.clearToken();
                abp.auth.clearRefreshToken();
                let localStorage = new LocalStorageService();
                localStorage.removeItem(AppConsts.authorization.autoLoginInfo);

                localStorage.removeItem(AppConsts.authorization.encrptedAuthTokenName,
                    () => {
                        if (reload !== false) {
                            if (returnUrl) {
                                location.href = returnUrl;
                            } else {
                                location.href = '';
                            }
                        }
                    }
                );
            }
        );
    }
}
