export class AppConsts {
    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static applicantBaseUrl = '/app';
    static applicantStartUrl = '/app/my-sirona';
    static authorityBaseUrl = '/app/authority';
    static authorityStartUrl = '/app/authority/applications';
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Sirona',
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token',
        autoLoginInfo: 'auto_login_info',
    };

    static readonly grid = {
        defaultPageSize: 10,
        maxPageSize: 1000, // see server side AppConsts.MaxPageSize
    };

    static readonly maintenance = class setting {
        static readonly enableMaintenanceBanner = 'App.Maintenance.EnableMaintenanceBanner';
        static readonly disableLiaCreateUntil = 'App.Maintenance.DisableLiaCreateUntil';
        static readonly disableLiaSubmissionUntil = 'App.Maintenance.DisableLiaSubmissionUntil';
        static readonly disableLiaServiceUntil = 'App.Maintenance.DisableLiaServiceUntil';
        static readonly disableLiaForAuthority = 'App.Maintenance.DisableLiaForAuthority';
        static readonly disableEhcCreateUntil = 'App.Maintenance.DisableEhcCreateUntil';
        static readonly disableEhcSubmissionUntil = 'App.Maintenance.DisableEhcSubmissionUntil';
        static readonly disableEhcServiceUntil = 'App.Maintenance.DisableEhcServiceUntil';
        static readonly disableEhcForAuthority = 'App.Maintenance.DisableEhcForAuthority';
        static readonly disableDlaCreateUntil = 'App.Maintenance.DisableDlaCreateUntil';
        static readonly disableDlaSubmissionUntil = 'App.Maintenance.DisableDlaSubmissionUntil';
        static readonly disableDlaServiceUntil = 'App.Maintenance.DisableDlaServiceUntil';
        static readonly disableDlaForAuthority = 'App.Maintenance.DisableDlaForAuthority';
    };

    static readonly sirona = class {
        static readonly setting = class {
            static readonly disableLanguageOfCorrespondence = 'App.Sirona.Setting.DisableLanguageOfCorrespondence';
            static readonly takeIntoAccountInactiveLicencesInAnotherCanton = 'App.Sirona.Setting.TakeIntoAccountInactiveLicencesInAnotherCanton';
            static readonly ignoreLanguageRequirementIfLicenceInAnotherCanton =
                'App.Sirona.Setting.IgnoreLanguageRequirementIfLicenceInAnotherCanton';
            static readonly ignoreEmploymentAddressPostCodeHasToBeInCanton =
                'App.Sirona.Setting.IgnoreEmploymentAddressPostCodeHasToBeInCanton';
            static readonly disableRejectLicenceAction = 'App.Sirona.Setting.DisableRejectLicenceAction';
            static readonly disableApplicationReactivation = 'App.Sirona.Setting.DisableApplicationReactivation';
            static readonly enableEhcIssuing = 'App.Sirona.Setting.EnableEhcIssuing';

            static readonly useOldLoginPage = 'App.Sirona.Setting.UseOldLoginPage';
            static readonly showUsernamePasswordInLoginForm = 'App.Sirona.Setting.ShowUsernamePasswordInLoginForm';
            static readonly showTextSigninWithInLoginForm = 'App.Sirona.Setting.ShowTextSigninWithInLoginForm';
            static readonly showExternalLoginProviderInLoginForm =
                'App.Sirona.Setting.ShowExternalLoginProviderInLoginForm';
            static readonly showExternalLoginProviderInFooter = 'App.Sirona.Setting.ShowExternalLoginProviderInFooter';
            static readonly showForgotPasswordInLoginForm = 'App.Sirona.Setting.ShowForgotPasswordInLoginForm';
            static readonly showEmailActivationInLoginForm = 'App.Sirona.Setting.ShowEmailActivationInLoginForm';
            static readonly showRegisterInLoginForm = 'App.Sirona.Setting.ShowRegisterInLoginForm';
            static readonly uploadMaxFileCount = 'App.Sirona.Setting.UploadMaxFileCount';
            static readonly maxFileLengthForDocuments = 'App.Sirona.Setting.MaxFileLengthForDocuments';
            static readonly allowedFileExtensionsForDocuments = 'App.Sirona.Setting.AllowedFileExtensionsForDocuments';
            static readonly malwareDetectionEnabled = 'App.Sirona.Setting.MalwareDetectionEnabled';

            static readonly enableExtendedEhcQualityRequirement = 'App.Sirona.Setting.EnableExtendedEhcQualityRequirement';
            static readonly enableExemptionAccordingArt37KvgForEhcPractice = 'App.Sirona.Setting.EnableExemptionAccordingArt37KvgForEhcPractice';

            static readonly enableApplicationSubmittedNotification = 'App.Sirona.Setting.EnableApplicationSubmittedNotification';
            static readonly enableNewSupplementalClaimNotification = 'App.Sirona.Setting.EnableNewSupplementalClaimNotification';

            static readonly enableSelfEmploymentTypeInEmploymentChangeRequest = 'App.Sirona.Setting.EnableSelfEmploymentTypeInEmploymentChangeRequest';
            static readonly enableIgnoreNaregLicenceInThisCantonLicenceStatus = 'App.Sirona.Setting.EnableIgnoreNaregLicenceInThisCantonLicenceStatus';

            static readonly showExternalLoginProviderFacebookInLoginForm = 'App.Sirona.Setting.ShowExternalLoginProviderFacebookInLoginForm';
            static readonly showExternalLoginProviderFacebookInFooter = 'App.Sirona.Setting.ShowExternalLoginProviderFacebookInFooter';

            static readonly showExternalLoginProviderGoogleInLoginForm = 'App.Sirona.Setting.ShowExternalLoginProviderGoogleInLoginForm';
            static readonly showExternalLoginProviderGoogleInFooter = 'App.Sirona.Setting.ShowExternalLoginProviderGoogleInFooter';

            static readonly showExternalLoginProviderMicrosoftInLoginForm = 'App.Sirona.Setting.ShowExternalLoginProviderMicrosoftInLoginForm';
            static readonly showExternalLoginProviderMicrosoftInFooter = 'App.Sirona.Setting.ShowExternalLoginProviderMicrosoftInFooter';

            static readonly showExternalLoginProviderOpenIdConnectInLoginForm = 'App.Sirona.Setting.ShowExternalLoginProviderOpenIdConnectInLoginForm';
            static readonly showExternalLoginProviderOpenIdConnectInFooter = 'App.Sirona.Setting.ShowExternalLoginProviderOpenIdConnectInFooter';

            static readonly showExternalLoginProviderBELoginInLoginForm = 'App.Sirona.Setting.ShowExternalLoginProviderBELoginInLoginForm';
            static readonly showExternalLoginProviderBELoginInFooter = 'App.Sirona.Setting.ShowExternalLoginProviderBELoginInFooter';

            static readonly showExternalLoginProvidereKontoInLoginForm = 'App.Sirona.Setting.ShowExternalLoginProvidereKontoInLoginForm';
            static readonly showExternalLoginProvidereKontoInFooter = 'App.Sirona.Setting.ShowExternalLoginProvidereKontoInFooter';

            static readonly showExternalLoginProviderWsFederationInLoginForm = 'App.Sirona.Setting.ShowExternalLoginProviderWsFederationInLoginForm';
            static readonly showExternalLoginProviderWsFederationInFooter = 'App.Sirona.Setting.ShowExternalLoginProviderWsFederationInFooter';

            static readonly showExternalLoginProviderTwitterInLoginForm = 'App.Sirona.Setting.ShowExternalLoginProviderTwitterInLoginForm';
            static readonly showExternalLoginProviderTwitterInFooter = 'App.Sirona.Setting.ShowExternalLoginProviderTwitterInFooter';

            static readonly mySironaPersonApplicationDisplayStatus = 'App.Sirona.Setting.MySironaPersonApplicationDisplayStatus';
            static readonly enableStrictNotifications = 'App.Sirona.Setting.EnableStrictNotifications';
            static readonly showPaymentInAdvanceWarning = 'App.Sirona.Setting.ShowPaymentInAdvanceWarning';

        };
    };

    static readonly icons = class {
        static readonly wizard = class {
            static readonly ehc = class {
                static readonly selfEmployment: string = 'fa fa-user fa-fw';
                static readonly register: string = 'fa fa-briefcase-medical fa-fw';
                static readonly education: string = 'fa fa-graduation-cap fa-fw';
                static readonly medicalField: string = 'fa fa-stethoscope fa-fw';
                static readonly languageSkill: string = 'fa fa-comment-dots fa-fw';
                static readonly practice: string = 'fa fa-microscope fa-fw';
                static readonly electronicPatientRecord: string = 'fa fa-laptop-medical fa-fw';
                static readonly qualityRequirement: string = 'fa fa-list-check fa-fw';
                static readonly contact: string = 'fa fa-id-card fa-fw';
                static readonly employment: string = 'fa fa-user-md fa-fw';
                static readonly invoiceAddress: string = 'fa fa-dollar-sign fa-fw';
            };
            static readonly lia = class {
                static readonly person: string = 'fa fa-user fa-fw';
                static readonly contact: string = 'fa fa-id-card fa-fw';
                static readonly restriction: string = 'fa fa-thumbs-up fa-fw';
                static readonly insurance: string = 'fa fa-heartbeat fa-fw';
                static readonly employment: string = 'fa fa-user-md fa-fw';
                static readonly previousEmployment: string = 'fa fa-clock-rotate-left fa-fw';
                static readonly invoiceAddress: string = 'fa fa-dollar-sign fa-fw';
                static readonly document: string = 'fa fa-file-upload fa-fw';
                static readonly languageSkill: string = 'fa fa-comment-dots fa-fw';
                static readonly diploma: string = 'fa fa-scroll fa-fw';
                static readonly academicTitle: string = 'fa fa-graduation-cap fa-fw';
                static readonly postgraduateTitlePrivateLaw: string = 'fa fa-chalkboard fa-fw';
                static readonly postgraduateTitle: string = 'fa fa-chalkboard-user fa-fw';
                static readonly guarantee: string = 'fa fa-notes-medical fa-fw';
                static readonly licenceInAnotherCanton: string = 'fa fa-stamp fa-fw';
                static readonly vaccination: string = 'fa fa-light fa-syringe';
            };
        };
    };

    static readonly codes = class {
        static readonly unsuccessfulLicenceApplication = class {
            static readonly canton = 'UnsuccessfulLicenceApplication-Canton';
            static readonly country = 'UnsuccessfulLicenceApplication-Country';
            static readonly cantonAndCountry = 'UnsuccessfulLicenceApplication-CantonAndCountry';
            static readonly none = 'UnsuccessfulLicenceApplication-None';
        };
        static readonly address = class {
            static readonly private: string = 'Address-Private';
            static readonly business: string = 'Address-Business';
            static readonly invoice: string = 'Address-Invoice';
        };
        static readonly businessType = class {
            static readonly akutspital: string = 'BusinessType-Akutspital';
            static readonly altersUndPflegeheim: string = 'BusinessType-Alters-undPflegeheim';
            static readonly geburtshaus: string = 'BusinessType-Geburtshaus';
            static readonly practiceAddress: string = 'BusinessType-PracticeAddress';
        };
        static readonly canton = class {
            static readonly be: string = 'Canton-Bern';
            static readonly original: string = 'Canton-Urkantone';
            static readonly unknown: string = 'Canton-Unbekannt';
        };
        static readonly employmentType = class {
            static readonly employed: string = 'EmploymentType-Employed';
            static readonly selfEmployed: string = 'EmploymentType-SelfEmployed';
        };
        static readonly businessLegalForm = class {
            static readonly unknown: string = 'BusinessLegalForm-Unknown';
        };
        static readonly ProfessionalLicenceType = class {
            static readonly licence: string = 'ProfessionalLicenceType-Licence';
            static readonly ninetyDays: string = 'ProfessionalLicenceType-NinetyDays';
        };
        static readonly country = class {
            static readonly ch: string = 'Country-Schweiz';
            static readonly de: string = 'Country-Deutschland';
            static readonly fr: string = 'Country-Frankreich';
            static readonly nl: string = 'Country-Niederlande';
            static readonly at: string = 'Country-Oesterreich';
            static readonly it: string = 'Country-Italien';
            static readonly gr: string = 'Country-Griechenland';
            static readonly be: string = 'Country-Belgien';
            static readonly pl: string = 'Country-Polen';
            static readonly gb: string = 'Country-Grossbritannien und Nordirland';
            static readonly hu: string = 'Country-Ungarn';
            static readonly ro: string = 'Country-Rumaenien';
            static readonly pt: string = 'Country-Portugal';
            static readonly es: string = 'Country-Spanien';
            static readonly cz: string = 'Country-Tschechische Republik';
            static readonly se: string = 'Country-Schweden';
            static readonly sk: string = 'Country-Slowakische Republik (Slowakei)';
            static readonly bg: string = 'Country-Bulgarien';
            static readonly fi: string = 'Country-Finnland';
            static readonly lt: string = 'Country-Litauen';
            static readonly hr: string = 'Country-Kroatien';
            static readonly lu: string = 'Country-Luxemburg';
            static readonly li: string = 'Country-Fuerstentum Liechtenstein';
            static readonly yu: string = 'Country-Jugoslawien';
        };
        static readonly applicationReleaseAction = class {
            static readonly ehcAndLia: string = 'ApplicationReleaseAction-EhcAndLia';
            static readonly liaOnly: string = 'ApplicationReleaseAction-LiaOnly';
            static readonly withdrawAll: string = 'ApplicationReleaseAction-WithdrawAll';
            static readonly createAdditionalApplication: string = 'ApplicationReleaseAction-CreateAdditionalApplication';
        };
        static readonly identificationStatus = class {
            static readonly none: string = 'IdentificationStatus-None';
            static readonly underConstruction: string = 'IdentificationStatus-UnderConstruction';
            static readonly submitted: string = 'IdentificationStatus-Submitted';
            static readonly evaluation: string = 'IdentificationStatus-Evaluation';
            static readonly granted: string = 'IdentificationStatus-Granted';
            static readonly rejected: string = 'IdentificationStatus-Rejected';
        };
        static readonly identificationType = class {
            static readonly documentUpload: string = 'IdentificationType-IdentificationCardDocumentUpload';
            static readonly applicationDocument: string = 'IdentificationType-ApplicationDocument';
        };
        static readonly changeRequestStatus = class {
            static readonly submitted: string = 'ChangeRequestStatus-Submitted';
            static readonly underEvaluation: string = 'ChangeRequestStatus-UnderEvaluation';
            static readonly autoGranted: string = 'ChangeRequestStatus-AutoGranted';
            static readonly granted: string = 'ChangeRequestStatus-Granted';
            static readonly rejected: string = 'ChangeRequestStatus-Rejected';
            static readonly completed: string = 'ChangeRequestStatus-Completed';
        };
        static readonly language = class {
            static readonly german: string = 'Language-German';
            static readonly french: string = 'Language-French';
        };
        static readonly languageSkill = class {
            static readonly german: string = 'LanguageSkill-German';
            static readonly french: string = 'LanguageSkill-French';
            static readonly other: string = 'LanguageSkill-Other';
        };
        static readonly professionalLicenceStatus = class {
            static readonly granted: string = 'ProfessionalLicenceStatus-Granted';
            static readonly banned: string = 'ProfessionalLicenceStatus-Banned';
            static readonly refused: string = 'ProfessionalLicenceStatus-Refused';
            static readonly restricted: string = 'ProfessionalLicenceStatus-Restricted';
            static readonly requested: string = 'ProfessionalLicenceStatus-Requested';
            static readonly inactive: string = 'ProfessionalLicenceStatus-Inactive';
            static readonly revoked: string = 'ProfessionalLicenceStatus-Revoked';
            static readonly noLicence: string = 'ProfessionalLicenceStatus-NoLicence';
        };
        static readonly expenseHealthCarePersonalLicenceStatus = class {
            static readonly granted: string = 'ExpenseHealthCarePersonalLicenceStatus-Granted';
            static readonly refused: string = 'ExpenseHealthCarePersonalLicenceStatus-Refused';
        };
        static readonly postgraduateTitle = class {
            static readonly other: string = 'PostgraduateTitle-Other';
        };
        static readonly profession = class {
            // (medreg)
            static readonly physician: string = 'Profession-Physician';
            static readonly dentist: string = 'Profession-Dentist';
            static readonly pharmacist: string = 'Profession-Pharmacist';
            static readonly chiropractor: string = 'Profession-Chiropractor';

            // (nareg)
            static readonly nursing: string = 'Profession-Nursing';
            static readonly physiotherapist: string = 'Profession-Physiotherapist';
            static readonly occupationalTherapist: string = 'Profession-OccupationalTherapist';
            static readonly midwife: string = 'Profession-Midwife';
            static readonly nutritionist: string = 'Profession-Nutritionist';
            static readonly optometrist: string = 'Profession-Optometrist';
            static readonly osteopath: string = 'Profession-Osteopath';
            static readonly optician: string = 'Profession-Optician';
            static readonly podiatrist: string = 'Profession-Podiatrist';
            static readonly druggist: string = 'Profession-DruggistHF';
            static readonly dentalHygienist: string = 'Profession-DentalHygienist';
            static readonly paramedic: string = 'Profession-Paramedic';
            static readonly therapistTCM: string = 'Profession-TherapistTCM';
            static readonly alternativePractitionerTEN: string = 'Profession-AlternativePractitionerTEN';
            static readonly homoeopath: string = 'Profession-Homeopath';
            static readonly acupuncturist: string = 'Profession-Acupuncturist';

            // (psyreg)
            static readonly psychotherapist: string = 'Profession-Psychotherapist';
            static readonly neuropsychologist: string = 'Profession-Neuropsychologist';

            static readonly andere: string = 'Profession-Andere';
        };
        static readonly yesNo = class {
            static readonly yes: string = 'YesNo-Yes';
            static readonly no: string = 'YesNo-No';
        };
        static readonly electronicPatientRecord = class {
            static readonly baseCommunityEHealthAargau: string = 'ElectronicPatientRecord-BaseCommunityEhealthAargau';
            static readonly baseCommunityESANITA: string = 'ElectronicPatientRecord-BaseCommunityESANITA';
            static readonly cara: string = 'ElectronicPatientRecord-CARA';
            static readonly referenceCommunityNeuchatel: string = 'ElectronicPatientRecord-ReferenceCommunityNeuchatel';
            static readonly associationEHealthTicino: string = 'ElectronicPatientRecord-AssociationEHealthTicino';
            static readonly xadStammgemeinschaft: string = 'ElectronicPatientRecord-XADBaseCommunity';
            static readonly abilisAG: string = 'ElectronicPatientRecord-AbilisAG';
            static readonly adSwiss: string = 'ElectronicPatientRecord-ADSwiss';
            static readonly other: string = 'ElectronicPatientRecord-Other';
        };
        static readonly documentType = class {
            static readonly other: string = 'Document-Other';
            static readonly centralCriminalRecordExtract: string = 'Document-CentralCriminalRecordExtract';
            static readonly foreignCriminalRecordExtract = 'Document-ForeignCriminalRecordExtract';
            static readonly curriculumVitae: string = 'Document-CurriculumVitae';
            static readonly postgraduatePractiseCertificate: string = 'Document-PostgraduatePractiseCertificate';
            static readonly medicalCertificate: string = 'Document-MedicalCertificate';
            static readonly professionalLicence: string = 'Document-ProfessionalLicence';
            static readonly professionalLicenceCoverLetter: string = 'Document-ProfessionalLicenceCoverLetter';
            static readonly statute: string = 'Document-Statute';
            static readonly timeSchedule: string = 'Document-TimeSchedule';
            static readonly coverLetter: string = 'Document-CoverLetter';
            static readonly operationLicence: string = 'Document-OperationLicence';
            static readonly proofOfLanguageProficiency: string = 'Document-ProofOfLanguageProficiency';
            static readonly diplomaOfAtLeastThreeYearsOfProfessionalTraining: string =
                'Document-DiplomaOfAtLeastThreeYearsOfProfessionalTraining';
            static readonly evidencOfRequiredHours: string = 'Document-EvidencOfRequiredHours';
            static readonly confirmatioOfActivityUnderProfessionalSupervision: string =
                'Document-ConfirmatioOfActivityUnderProfessionalSupervision';
            static readonly proofOfPractice: string = 'Document-ProofOfPractice';
            static readonly decree: string = 'Document-Decree';
            static readonly accompanyingLetter: string = 'Document-AccompanyingLetter';
        };

        static readonly daycareRole = class {
            static readonly betriebsfuerhung: string = 'DaycareRole-Betriebsfuehrung';
            static readonly paedagogischeFuehrung: string = 'DaycareRole-PaedagogischeFuehrung';
            static readonly personalfuehrung: string = 'DaycareRole-Personalfuehrung';
            static readonly mitarbeitMitBetreuungsverantwortung: string =
                'DaycareRole-MitarbeitMitBetreuungsverantwortung';
            static readonly mitarbeitBetreuung: string = 'DaycareRole-MitarbeitBetreuung';
        };

        static readonly daycareQualification = class {
            static readonly apprenticeWithSufficientExperience: string =
                'DaycareQualification-ApprenticeWithSufficientExperience';
            static readonly employmentContract: string = 'DaycareQualification-EmploymentContract';
            static readonly specialistCareOrEquivalentTraining: string =
                'DaycareQualification-SpecialistCareOrEquivalentTraining';
            static readonly trainingForEquivalenceProcess: string =
                'DaycareQualification-TrainingForEquivalenceProcess';
        };
        static readonly daycareProfession = class {
            static readonly faBeEFZ: string = 'Profession-FachfrauFachmannBetreuungFachrichtungKindEFZ';
            static readonly kleinkinderzieher: string = 'Profession-KleinkinderzieherKleinkinderzieherin';
            static readonly kindheitspaedagogeHF: string =
                'Profession-KinderzieherinKindererzieherHFKindheitspaedagogeKindheitspaedagoginHF';
            static readonly erzieherMSADA: string = 'Profession-ErzieherErzieherinMSADA';
            static readonly sozialpaedagogeHF: string = 'Profession-SozialpaedagoginSozialpaedagogeHF';
            static readonly kindergaertner: string = 'Profession-KindergaertnerKindergaertnerin';
            static readonly sozialarbeiterHFFH: string = 'Profession-SozialarbeiterSozialarbeiterinHFFH';
            static readonly soziokulturellerAnimatorHFFH: string =
                'Profession-SoziokulturelleAnimatorinSoziokulturellerAnimatorHFFH';
        };
        static readonly evaluationStatus = class {
            static readonly ok: string = 'EvaluationStatus-Ok';
            static readonly notOk: string = 'EvaluationStatus-NotOk';
            static readonly internalClarificationRequired: string = 'EvaluationStatus-InternalClarificationRequired';
        };

        static readonly psyreg = class {
            static readonly postgraduateTitle = class {
                static readonly type = class {
                    static readonly federal: string = 'PostgraduateTitle-PsychologyFederal';
                    static readonly foreignAndRecognized: string = 'PostgraduateTitle-PsychologyForeignAndRecognized';
                    static readonly foreignAndVerified: string = 'PostgraduateTitle-PsychologyForeignAndVerified';
                };
            };
        };

        static readonly diploma = class {
            static readonly psyreg = class {
                static readonly type = class {
                    static readonly federalPsyReg: string = 'DiplomaType-FederalPsyReg';
                    static readonly foreignAndRecognized: string = 'DiplomaType-ForeignAndRecognized';
                    static readonly foreignAndVerified: string = 'Diploma-ForeignAndVerified';
                };
            };

            static readonly medreg = class {
                static readonly type = class {
                    static readonly federal: string = 'DiplomaType-Federal';
                    static readonly mebekoRecognized: string = 'DiplomaType-MebekoRecognized';
                    static readonly mebekoEquivalent: string = 'DiplomaType-MebekoEquivalent';
                    static readonly other: string = 'DiplomaType-Other';
                    static readonly verifiedAccordingToBGMD: string = 'DiplomaType-VerifiedAccordingToBGMD';
                    static readonly foreignCheckedAndNotRecognized: string =
                        'DiplomaType-ForeignCheckedAndNotRecognized';
                };
            };
        };

        static readonly external = class {
            static readonly medreg = class {
                static readonly activity = class {
                    static readonly active = 'ProfessionalLicenceActivityStatus-Active';
                    static readonly inactive = 'ProfessionalLicenceActivityStatus-Inactive';
                };

                static readonly diploma = class {
                    static readonly typ = class {
                        static readonly ueberprueftNichtAnnerkant: number = 15006;
                    };
                };
                static readonly weiterbildungstitel = class {
                    static readonly typ = class {
                        static readonly eidg: number = 16000;
                        static readonly annerkanterMebeko: number = 16001;
                        static readonly gleichwertigerMebeko: number = 16002;
                        static readonly andere: number = 16003;
                        static readonly unbekannt: number = 16004;
                        static readonly nachgeprueftBGMD: number = 16005;
                    };
                };
            };


            static readonly nareg = class {
                static readonly licence = class {
                    static readonly type = class {
                        static readonly dla90: number = 11002;
                    };
                };
            };
        };
    };

    static readonly MinimumUpgradePaymentAmount = 1;

    static readonly LuxonDateFormat = 'd.L.yyyy';
    static readonly PrimeNgDateFormat = 'dd.mm.yy';
    /// <summary>
    /// Gets current version of the application.
    /// It's also shown in the web page.
    /// </summary>
    /// <summary>
    /// Redirects users to host URL when using subdomain as tenancy name for not existing tenants
    /// </summary>
    static readonly PreventNotExistingTenantSubdomains = false;
    static readonly WebAppGuiVersion = '10.3.0';

    static readonly features = class {
        static readonly personDossier: string = 'App.PersonDossier';
        static readonly dashboard: string = 'App.Dashboard';
    };
}
